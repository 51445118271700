import React from "react"
import {graphql} from "gatsby"

import SEO from "src/components/Element/seo"
import { Consumer } from "src/utils/contexts"

import PostListHome from "src/components/Domain/Post/post_list_home"

const Home = ({data, location}) => {
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allStrapiPost.edges
    const config = data.allStrapiConfig.edges[0].node

    let posts_for_show = []
    posts.forEach((obj) => {
        posts_for_show.push(obj.node)
    })

    return (
        <Consumer>
            {({store, functions}) => (
                <>
                    <SEO
                        title={siteTitle}
                        description={config.site_description}
                    />
                    <div id="home" className="main-content">
                        <div className="article-list-wrapper">
                            <PostListHome posts={posts_for_show} />
                        </div>
                    </div>
                </>
            )}
        </Consumer>
    )
}

export default Home

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        # https://strapi.io/documentation/3.0.0-alpha.x/guides/filters.html#available-operators
        allStrapiPost(
            sort: { fields: [date], order: DESC }
            limit: 10000
            filter: {
                visible: {eq: true}
            }
        ) {
            edges {
                node {
                    id
                    title
                    date
                    content
                    uri
                    summary
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 480) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    visible
                }
            }
        }
        allStrapiConfig {
            edges {
                node {
                    site_description
                }
            }
        }
    }
`
